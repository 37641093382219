<template>
  <Dialog
    :name="name"
    width="475px"
    class="add-timeslot"
    @close="handleCloseWithoutSubmit"
  >
    <template #title>
      <div style="font-size: 16px; font-weight: 400">Добавление авто</div>
    </template>

    <div>
      <el-form
        ref="formAddTimeslot"
        :model="formAddTimeslot"
        :rules="rules"
        class="form-add-timeslot"
        style="flex-direction: column"
        @submit.native.prevent
      >
        <div class="df" style="flex-direction: column">
          <div class="m-b-5">Номер авто</div>
          <IqInputSearchForm
            v-model="formAddTimeslot.numAuto"
            input-width="100%"
            prop="numAuto"
            :rules="rules.numAuto"
            :disabled="isLoading"
            :loading="isLoading"
            @search="handleCloseDialog"
            @keydown.native.enter="handleCloseDialog"
          />
        </div>
      </el-form>
    </div>
  </Dialog>
</template>

<script>
import {
  FETCH_TIMESLOT,
  GET_IS_LOADING_POST_TIMESLOTS,
} from '@/views/manager/store/actions'
import { MANAGER_ADD_TIMESLOT } from '@/constants/dialogs'
import { mapActions, mapGetters } from 'vuex'
import { ruleFormAddTimeslot } from '@/views/manager/components/dialogs/dialog-add-timeslot/data/dialogAddTimeslot'
import Dialog from '@/UI/dialog/Dialog'
import IqInputSearchForm from '@/UI/form/input/IqInputSearchForm'

export default {
  name: 'DialogAddTimeslot',
  components: { Dialog, IqInputSearchForm },
  data() {
    return {
      name: MANAGER_ADD_TIMESLOT,
      formAddTimeslot: { numAuto: '' },
      rules: ruleFormAddTimeslot,
    }
  },
  computed: {
    ...mapGetters({
      isLoading: GET_IS_LOADING_POST_TIMESLOTS,
    }),
  },
  methods: {
    ...mapActions({ fetchTimeslot: FETCH_TIMESLOT }),
    handleCloseDialog() {
      this.$refs.formAddTimeslot.validate(valid => {
        if (valid) {
          this.fetchTimeslot({
            plate_truck: this.formAddTimeslot.numAuto,
            setFormFields: this.setFormFields,
            nameDialog: this.name,
          })
        }
      })
    },
    handleCloseWithoutSubmit() {
      this.setFormFields()
      this.$refs.formAddTimeslot.clearValidate()
    },
    setFormFields(numAuto = '') {
      this.formAddTimeslot.numAuto = numAuto
    },
  },
}
</script>

<style lang="sass">
.form-add-timeslot
  display: flex
  padding-bottom: 100px
  .el-form-item
    margin: 0 !important
  &__btn-wrap
    margin-left: 30px
  &__btn
    font-size: 28px
    cursor: pointer
    color: $color-black
    transition: all .3s ease
    padding: 0 !important
    border: none
    &:hover, &:focus
      color: $btn-orange
      background: transparent

  .el-form-item__content
    position: static

  .el-form-item
    position: relative
</style>
